/* background.css */

.homepage-icon-black {
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: black;
}

.homepage-icon-white {
	font-size: 30px;
	transition: color 0.2s ease-in-out;
	color: white;
}
