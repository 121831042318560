@tailwind base;
@tailwind components;
@tailwind utilities;

.footer {
	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
}
