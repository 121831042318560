@tailwind base;
@tailwind components;
@tailwind utilities;

.hero {
	position: relative;
	overflow: hidden;
}

.blue-dot {
	position: absolute;
	/*background-color: rgb(35, 68, 255);*/
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1; /* Ensure it stays in the background */
}

.hero .container {
	position: relative;
	z-index: 10; /* Ensure content stays in the foreground */
}
