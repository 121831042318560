@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes scroll {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-50%);
	}
}

.animate-scroll {
	display: flex;
	animation: scroll 20s linear infinite;
}
