@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Inter';
	src: url('../public/fonts/Inter.woff2') format('woff2'),
		 url('../public/fonts/Inter.woff') format('woff');
	font-weight: normal;
	font-style: normal;
  }